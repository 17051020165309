import React from 'react'
import { Link } from 'react-router-dom'
import { Button, H2 } from '../components/Tags'
import { P } from '../components/Tags'

function About() {
    return (
        <div className="p-8 flex flex-col text-gray-800 text-center bg-gray-200
        justify-center items-center">
            <h1 className="lg:text-4xl text-3xl text-green-800 font-bold py-4">A propos de J P Flowers</h1>
            <div className="m-6 flex flex-col text-center"></div>
                <div className="m-4">
                    <H2>A propos</H2>
                    <P>Provenant d'une famille de professionels dans le domaine des fleurs depuis trois générations,</P>
                    <P>J P Flowers est à votre disposition pour vous proposer un service de grossiste en fleurs de qualité</P>
                </div>
                <div className="m-4">
                    <Button to='/contact'>
                        Nous contacter
                    </Button>
                </div>
        </div>
    )
}

export default About

import React from 'react'
import { Link } from 'react-router-dom'
import Menus from './Menus'

const Navbar = ({toggle}) => {
    return (
        <nav className="flex justify-between items-center h-16 bg-white text-black relative shadow-sm bg-gray-600">
            <Link to="/" translate="no" className="text-green-200 text-xl pl-8 font-bold">J P Flowers</Link>
            <div className="px-4 cursor-pointer md:hidden" onClick={toggle}>
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </div>
            <div className="pr-8 text-gray-100 font-semibold md:block hidden">
                <Menus />
            </div>
        </nav>
    )
}

export default Navbar

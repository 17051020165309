import React from 'react'
import {Link} from 'react-router-dom'
import Menus from './Menus'

function Dropdown({isOpen, toggle}) {
    return (
        <div className={isOpen ? "grid grid-rows-3 text-center items-center bg-gray-500 text-gray-100" : "hidden"}>
            <Menus />
        </div>
    )
}

export default Dropdown
 import React from 'react'

function Footer() {
    return (
        <div className="flex justify-center items-center h-16 bg-gray-700 text-gray-100">
            <p>Copyright 2021 JPFlowers All right reserved - Website by <a href="mailto:alexandre.englebert.pro@gmail.com">Alexandre Englebert</a></p>
        </div>
    )
}

export default Footer

import React from 'react'
import { Link } from 'react-router-dom'

export function H2({children}) {
    return (
        <h2 className="m-4 lg:text-2xl text-xl text-green-900 font-semibold">{children}</h2>
    )
}

export function P({className, children}) {
    return (
        <p className="pl-4">{children}</p>
    )
}

export function Button({to, children})  {
    return (
        <Link to={to} className="md:py-4 md:px-6 py-3 px-4 rounded-full bg-green-500 
        md:text-2xl sm:text-xl font-bold 
        text-md hover:bg-green-400 text-gray-800 hover:text-gray-900
        transition ease-in-out duration-300 flex items-center">
            {children}
        </Link>
    )
}

export function Entreprise({name, url, city, icon}) {
    return (
            <div className="bg-white  mx-auto my-1 shadow-lg rounded-lg">
                <a href={url}><img className="rounded-t-lg w-40 h-16 md:w-40 md:h-20 object-contain" src={icon} alt="" /></a>
                <div className="py-2 px-2">
                    <h1 className="hover:cursor-pointer text-gray-900 font-bold text-sm md:text-lg tracking-tight"> <a href={url}>{name}</a> </h1>
                    <p className="text-gray-900 font-bold p-2 text-xs md:text-sm"> {city} </p>
                </div>
            </div>
    )   
}

export function Photo({children}) {
    return (
        <div className="mx-auto">
            <img className="shadow-lg rounded-lg w-72 h-72 sm:w-48 sm:h-48 md:w-72 md:h-72 object-cover" src={children} alt="Illustration"/>
        </div>
        
    )
}

export function PhotoV({children}) {
    return (
        <div className="mx-auto">
            <img className="shadow-lg rounded-lg w-72   h-96 sm:w-48 sm:h-56 md:w-72 md:h-96 object-cover" src={children} alt="Illustration"/>
        </div>
        
    )
}
import { H2 } from '../components/Tags'
import { P } from '../components/Tags'

function Contact() {
    return (
        <div className="p-8 flex flex-col text-gray-800 text-center bg-gray-200 shadow">
            <h1 className="lg:text-4xl text-3xl text-green-800 font-bold py-4">Nous contacter</h1>
            <P>Vous pouvez nous contacter par les moyens suivants</P>
            <div className="m-6 flex flex-col text-center">
                <div className="m-4">
                    <H2>Téléphone</H2>
                    <p className="text-sm text-green-900">(Du lundi au samedi de 7 à 19h)</p>
                    <P><a href="tel:+32485595522">+32 (0) 485 / 59 55 22</a></P>
                </div>
                <div className="m-4">
                <H2>E-mail</H2>
                <P><a href="mailto:jeanpolmichaux1963@gmail.com">jeanpolmichaux1963@gmail.com</a></P>
                </div>
                <div className="m-4">
                <H2>Réseaux sociaux</H2>
                <P><a href="https://www.instagram.com/michaux6368/"><i class='fa fa-instagram'></i> Instagram</a></P>
                <P><a href="https://www.facebook.com/groups/436804344464585/?ref=share"><i class='fa fa-facebook'></i> Facebook</a></P>
                </div>
            </div>
        </div>
    )
}

export default Contact

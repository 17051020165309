import React from 'react'
import {Link} from 'react-router-dom'

function Menus() {
    return (
        <>
            <Link className="p-4" to="/">Acceuil</Link>
            <Link className="p-4" to="/florist">Espace Fleuristes</Link>
            <Link className="p-4" to="/resto">Espace Restaurants et Hôtels</Link>
            <Link className="p-4" to="/about">A propos</Link>
            <Link className="p-4" to="/contact">Contact</Link>
        </>
    )
}

export default Menus

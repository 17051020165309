import React from 'react'
import Hero from '../components/Hero'

function Home() {
    return (
        <div className="h-screen flex flex-col">
            <Hero />
        </div>
    )
}

export default Home

import React from 'react'
import { Link } from 'react-router-dom'
import { Button, H2, Photo, PhotoV, P, Entreprise} from '../components/Tags'

import deco1 from '../images/deco/IMG_1207.jpg'
import deco2 from '../images/deco/IMG_1514.jpg'
import deco3 from '../images/deco/image1c.jpg'
import deco4 from '../images/deco/image0c.jpg'
/*import deco4 from '../images/deco/6044.jpg'*/


import comestible1 from '../images/comestibles/IMG_1480.jpg'
import comestible2 from '../images/comestibles/IMG_1495.jpg'
import comestible3 from '../images/comestibles/IMG_1481.jpg'
import comestible4 from '../images/comestibles/IMG_1490.jpg'

function Resto() {
    return (
        <div className="p-8 flex flex-col text-gray-800 text-center bg-gray-200
        justify-center items-center">
            <h1 className="lg:text-4xl text-3xl text-green-800 font-bold py-4">Espace Restaurants et Hôtels</h1>
            <div className="m-6 flex flex-col text-center"></div>
                <div className="m-4">
                    <H2>Décoration florale</H2>
                    <P>Si vous souhaitez embellir votre établissement (Hôtel, salle de restauration ou réception, chambres, entreprise,...),</P>
                    <P>pensez aux fleurs naturelles, compositions florales, bouquets, plantes vertes (jusqu'à 3m de haut), vasques d'orchidées,...</P>
                    <P>L'entretient des orchidées et plantes vertes est offert (arrosage toutes les trois semaines avec nutriments).</P>
                    <P>Nous pouvons également vous fournir les pots et accessoires qui accompagneront vos plantes.</P>
                </div>
                <div className="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 p-lg-5 gap-2 m-5 mb-10">
                <PhotoV>{deco1}</PhotoV>
                <PhotoV>{deco2}</PhotoV>
                <PhotoV>{deco3}</PhotoV>
                <PhotoV>{deco4}</PhotoV>
                </div>
                

                <div className="m-4">
                    <H2>Fleurs comestibles</H2>
                    <P>Si vous souhaitez proposer ajouter à vos plats une touche d'originalité, nous vous proposons également une grande variété de fleurs comestibles.</P>
                </div>
                <div className="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 p-lg-5 gap-2 m-5 mb-10">
                <Photo>{comestible1}</Photo>
                <Photo>{comestible2}</Photo>
                <Photo>{comestible3}</Photo>
                <Photo>{comestible4}</Photo>
                </div>

                <div className="m-4">
                    <H2>Ces établissement nous font confiance</H2>


                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 p-lg-5 gap-2 m-5 mb-10">
                        <Entreprise name="L'essentiel" city="Temploux" url="http://www.lessentiel.be" icon="http://www.lessentiel.be/wp-content/uploads/2018/06/Lessentiel_logo-1-300x145.png"></Entreprise>
                        <Entreprise name="Le D'Arville" city="Wierde" url="https://www.ledarville.be" icon="https://www.ledarville.be/wp-content/uploads/2018/06/Favicon-2.png"></Entreprise>
                        <Entreprise name="Le Barabas" city="Wierde" url="https://www.la-carte.be/fr/restaurants/belgique/namur/5100_wierde/barabas-la-suite-du-darville/" icon="https://www.la-carte.be/db/wierde/barabaslasuitedudarville/logo2.gif"></Entreprise>
                        <Entreprise name="Le Saint Loup" city="Gembloux" url="https://www.lesaintloup.be/gembloux/" icon="http://www.lesaintloup.be/wp-content/uploads/2020/07/St-loup-logo.png"></Entreprise>
                        <Entreprise name="Le Saint Loup" city="Marche-en-Famenne" url="https://www.lesaintloup.be/marche-en-famenne/" icon="http://www.lesaintloup.be/wp-content/uploads/2020/07/St-loup-logo.png"></Entreprise>
                        <Entreprise name="La Cité Imperiale Ye" city="Mettet" url="https://www.laciteimperiale.be/images/cite-imperiale-mettet.png" icon="https://www.laciteimperiale.be/images/cite-imperiale-mettet.png"></Entreprise>
                        <Entreprise name="La vache qui vole" city="Bois-de-Villers" url="http://www.lavachequivole.be/fr" icon="http://prod.chefminute-img.com/site/pictures-logo/0000000021.png?ts=1644145421"></Entreprise>
                        <Entreprise name="La Poêle vache" city="Spy" url="https://www.lapoelevache.be/" icon="https://www.lapoelevache.be/wp-content/uploads/sites/224/2015/05/logo.png"></Entreprise>
                        <Entreprise name="Les Vents d'Anges" city="Saint Denis" url="https://www.facebook.com/Les-Vents-dAnges-321079578694430/" 
                        icon="https://scontent.fcrl2-1.fna.fbcdn.net/v/t1.6435-9/114093366_739559350179782_7443454313614179193_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=wX7vhHC62T4AX8u2Myp&_nc_ht=scontent.fcrl2-1.fna&oh=00_AT8FRD-EDr98D_C8m6VaOeeeLF5On59jxph4UMDai6bpEg&oe=623B10B7"></Entreprise>
                    </div>
                    
                    <P>Et encore beaucoup d'autres ! Peut-être vous aussi ?</P>

                </div>

                <div className="m-4">
                    <Button to='/contact'>
                        Nous contacter
                    </Button>
                </div>
        </div>
    )
}

export default Resto

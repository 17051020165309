import React from 'react'
import { Link } from 'react-router-dom'

const bg_hero = {
    backgroundImage: 'linear-gradient(rgba(220, 250, 220, 0.4), rgba(220, 250, 220, 0.2)),url(https://images.unsplash.com/photo-1570575208431-47d5928d721c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80)',
}

function Hero() {
    return (
        <div style={bg_hero} className="pb-12 bg-white h-screen flex flex-col justify-center items-center">
            <h1 translate='no' className="pb-12 lg:text-9xl md:text-8xl sm:text-7xl text-6xl text-gray-900 font-bold mb-14">
                J P Flowers
            </h1>
            <p className="pb-16 px-4 lg:text-4xl md:text-3xl sm:text-3xl text-xl text-center 
            text-gray-900 justify-center font-semibold" >
                <span className="font-black">Grossiste</span> en fleurs, plantes et fleurs comestibles en région namuroise
                </p>
            <p className="md:py-4 md:px-8 py-3 px-4 mt-4 md:text-2xl sm:text-xl font-bold rounded-full bg-green-500 
            text-md hover:bg-green-400 text-gray-800 hover:text-gray-900
            transition ease-in-out duration-300 flex items-center">
            <Link to="/florist" >Espace Fleuristes</Link>
            </p>
            <p className="md:py-4 md:px-8 py-3 px-4 mt-4 md:text-2xl sm:text-xl font-bold rounded-full bg-green-500 
            text-md hover:bg-green-400 text-gray-800 hover:text-gray-900
            transition ease-in-out duration-300 flex items-center">
            <Link to="/resto" >Espace Restaurants et Hôtels</Link>
            </p>
            <Link to="/contact" className="md:py-6 md:px-8 py-3 px-4 mt-4 rounded-full bg-green-500 
            md:text-2xl sm:text-xl font-bold 
            text-md hover:bg-green-400 text-gray-800 hover:text-gray-900
            transition ease-in-out duration-300 flex items-center">
                Nous contacter
            </Link>

            <a className="my-2 md:text-2xl sm:text-xl font-bold
            text-md hover:text-green-700 text-black items-center"
            href="https://www.instagram.com/michaux6368/"><i class='fa fa-instagram'></i> Instagram</a>
            <a className="my-2 md:text-2xl sm:text-xl font-bold
            text-md hover:text-green-700 text-black items-center"
            href="https://www.facebook.com/groups/436804344464585/?ref=share"><i class='fa fa-facebook'></i> Facebook</a>
            
        </div>
    )
}

export default Hero

import React from 'react'
import { Link } from 'react-router-dom'
import { Button, H2, Photo } from '../components/Tags'
import { P } from '../components/Tags'



import grossiste1 from '../images/grossiste/6043_bis.jpg'
import grossiste2 from '../images/grossiste/image0.jpg'
import grossiste3 from '../images/grossiste/image1.jpg'
import grossiste4 from '../images/grossiste/image2.jpg'

import mortuaire1 from '../images/mortuaires/IMG_1376.jpg'
import mortuaire2 from '../images/mortuaires/IMG_1377.jpg'
import mortuaire3 from '../images/mortuaires/IMG_1378.jpg'
import mortuaire4 from '../images/mortuaires/IMG_1432.jpg'

function Florist() {
    return (
        <div className="p-8 flex flex-col text-gray-800 text-center bg-gray-200
        justify-center items-center">
            <h1 className="lg:text-4xl text-3xl text-green-800 font-bold py-4">Espace Fleuristes</h1>
            <div className="m-6 flex flex-col text-center"></div>
                <div className="m-4">
                    <H2>Nos produits</H2>
                    <P>Nous vous proposons un large assortiment de plantes vertes et fleuries ainsi que des montages mortuaires en soie de première qualité.</P>
                    <P>Nos prix sont très abordables en comparaison à la concurence et nous proposons également la livraison.</P>
                </div>
                <div className="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 p-lg-5 gap-2 m-5 mb-10">
                <Photo>{mortuaire1}</Photo>
                <Photo>{mortuaire2}</Photo>
                <Photo>{mortuaire3}</Photo>
                <Photo>{mortuaire4}</Photo>

                <Photo>{grossiste1}</Photo>
                <Photo>{grossiste2}</Photo>
                <Photo>{grossiste3}</Photo>
                <Photo>{grossiste4}</Photo>
                </div>
                <div className="m-4">
                    <Button to='/contact'>
                        Nous contacter
                    </Button>
                </div>
        </div>
    )
}

export default Florist
